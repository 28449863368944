const getAccountantDocTypes = (factMapByTag) => {
    // TODO: check if tags exist in product?
    const tagToDocType = {
        TypeOfAuditorAssistance:        'EXTERNAL_REPORT',
        TypeOfAuditorAssistance_Intern: 'INTERNAL_REPORT',
        TypeOfAuditorAssistance_Skat:   'SECONDARY_DOCUMENT',
    };

    const accountantDocTypes = [];

    for (const [tag, docType] of Object.entries(tagToDocType)) {
        const factValue = factMapByTag[tag]?.value || {};
        const selectedStatementType = factValue?.enumString || 'Intet';
        if (selectedStatementType !== 'Intet') {
            accountantDocTypes.push(docType);
        }
    }

    return accountantDocTypes;
};

const injectSigneesFromFactMap = (user, factMap, documents) => {
    const factMapByTag = {};
    for (const fact of Object.values(factMap)) {
        factMapByTag[fact.tag] = fact;
    }

    const externalAndInternal = ['EXTERNAL_REPORT', 'INTERNAL_REPORT'];
    const allReports = [...externalAndInternal, 'SECONDARY_DOCUMENT'];
    const accountantDocs = getAccountantDocTypes(factMapByTag);

    const roleOfCEO = user.isVirksomhed() ? 'Indehaver' : 'Adm. Direktør';
    const roleOfAudtior = factMapByTag.DescriptionOfAuditor?.value?.string || 'Revisor';

    const signeeRoles = {
        NameAndSurnameOfMemberOfExecutiveBoard_CEO:                { category: 'boardMember',              role: roleOfCEO,                    docTypes: [...allReports] },
        NameAndSurnameOfMemberOfExecutiveBoard:                    { category: 'boardMember',              role: 'Direktør',                   docTypes: [...allReports] },
        NameAndSurnameOfOtherMemberOfExecutiveBoard:               { category: 'boardMember',              role: 'Medlem af direktionen',      docTypes: [...allReports] },
        NameAndSurnameOfMemberOfSupervisoryBoard_Chairman:         { category: 'boardMember',              role: 'Bestyrelsesformand',         docTypes: [...externalAndInternal] },
        NameAndSurnameOfMemberOfSupervisoryBoard_ViceChairman:     { category: 'boardMember',              role: 'Bestyrelsesnæstformand',     docTypes: [...externalAndInternal] },
        NameAndSurnameOfMemberOfSupervisoryBoard:                  { category: 'boardMember',              role: 'Medlem af bestyrelsen',      docTypes: [...externalAndInternal] },
        NameAndSurnameOfMemberOfSupervisoryCommittee_Chairman:     { category: 'boardMember',              role: 'Formand for tilsynsråd',     docTypes: [...externalAndInternal] },
        NameAndSurnameOfMemberOfSupervisoryCommittee_ViceChairman: { category: 'boardMember',              role: 'Næstformand for tilsynsråd', docTypes: [...externalAndInternal] },
        NameAndSurnameOfMemberOfSupervisoryCommittee:              { category: 'boardMember',              role: 'Medlem af tilsynsråd',       docTypes: [...externalAndInternal] },
        NameAndSurnameOfAuditor:                                   { category: 'accountant',               role: roleOfAudtior,                docTypes: [...accountantDocs] },
        NameAndSurnameOfChairmanOfGeneralMeeting:                  { category: 'chairmanOfGeneralMeeting', role: 'Dirigent',                   docTypes: ['EXTERNAL_REPORT'] },
    };

    Object.entries(signeeRoles).forEach(([tag, { category, role, docTypes }]) => {
        const fact = factMapByTag[tag];
        const factValue = fact?.value || {};

        const names = [];

        if (factValue.string) {
            names.push(factValue.string);
        }

        if (Array.isArray(factValue.stringList)) {
            names.push(...factValue.stringList);
        }

        const signees = names.map(name => ({ category, name, role }));
        
        for (const docType of docTypes) {
            const relevantDocuments = documents.filter(document => document.kind === docType);
            relevantDocuments.forEach(document => {
                document.signees.push(...signees);
            });
        }
    });
};

const collectSystemDocuments = (user, docGenFact, factMap) => {
    const {
        pdf,
        signedPdf,
        virtualHTMLDocumentFileID,
        rawIXBRL,
        signees,
        
        internalPdf,
        signedInternalPdf,
        internalReportVirtualHTMLDocumentFileID,
        internalReportRawIXBRL,
        internalReportSignees,
        
        secondaryDocuments,
    } = docGenFact;

    const documents = [
        {
            id: 'EXTERNAL_REPORT',
            kind: 'EXTERNAL_REPORT',
            title: 'Årsrapport',
            virtualHTMLDocumentFileID,
            rawIXBRL,
            overrideConfigurationFlowContextPropertyPath: 'overrideConfiguration',
            pdfID: pdf,
            signedPdfID: signedPdf,
            signees: signees || [],
            mustAlwaysSign: true,
        },
    ];

    const userShouldSeeInternalDocuments = user.isAdmin() || user.isAccountant();
    if (userShouldSeeInternalDocuments) {
        if (internalReportVirtualHTMLDocumentFileID || internalPdf) {
            documents.push({
                id: 'INTERNAL_REPORT',
                kind: 'INTERNAL_REPORT',
                title: 'Internt regnskab',
                virtualHTMLDocumentFileID: internalReportVirtualHTMLDocumentFileID,
                rawIXBRL: internalReportRawIXBRL,
                overrideConfigurationFlowContextPropertyPath: 'internalReportOverrideConfiguration',
                pdfID: internalPdf,
                signedPdfID: signedInternalPdf,
                signees: internalReportSignees || [],
                mustAlwaysSign: false,
            });
        }
    
        for (let i = 0; i < (secondaryDocuments || []).length; i++) {
            const { documentMetadata, virtualHTMLDocumentFileID, rawIXBRL, pdfFileID, signees } = secondaryDocuments[i];
            documents.push({
                id: `SECONDARY_DOCUMENT:${documentMetadata.reportTemplateID}`,
                kind: 'SECONDARY_DOCUMENT',
                title: documentMetadata.title,
                virtualHTMLDocumentFileID,
                rawIXBRL,
                overrideConfigurationFlowContextPropertyPath: `secondaryDocumentsOverrideConfiguration.${documentMetadata.reportTemplateID}`,
                signedPdfID: pdfFileID,
                signees: signees || [],
                secondaryDocumentIndex: i,
                mustAlwaysSign: false,
            });
        }
    }

    const noSignees = documents.every(document => {
        return document.signees.length === 0;
    });

    if (noSignees) {
        injectSigneesFromFactMap(user, factMap, documents);
    }
    
    return documents;
};

export default collectSystemDocuments;