import ColoredText from 'design/atoms/ColoredText';
import FileuploadButton from 'design/atoms/FileuploadButton';
import { ProgressBar } from 'design/atoms/FoldableSegment';
import Stepper from 'design/atoms/Stepper';
import YesNo from 'design/atoms/YesNo';
import { statusesMap } from 'design/molecules/AppLauncher/userStatuses';
import UnlockModal from 'design/pages/Product/components/LockStatus/UnlockModal';
import { sendReminderBySigneeReference, updateEmail } from 'http/esignatur';
import { uploadFile } from 'http/file-storage';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox, Icon, Input, List, Loader, Modal, Popup, Table } from 'semantic-ui-react';
import getEmailRegexp from 'util/getEmailRegexp';
import useUser from 'util/useUser';
import SignatureFlowFilesModal from './SignatureFlowFilesModal';

const validateSignflow = ({ signees, documentsToValidate }) => {
    for (const signee of signees) {
        if (!signee.email) {
            return `Manglende email for ${signee.name}`;
        }

        if (!getEmailRegexp().test(signee.email)) {
            return `${signee.email} er ikke en valid email for underskriver ${signee.name}`;
        }

        if (!signee.signingMethod) {
            return `Manglende underskrivningsmetode for ${signee.name}`;
        }
    }

    for (const document of documentsToValidate) {
        if (document.categories.length === 0) {
            return `Ingen modtagere valgt for dokument: ${document.name}`;
        }
    }

    return null; // no errors
};

const ExtraDocument = ({ extraDocument, onChange, onDelete, categoriesToShow }) => {
    const theOptions = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
                <SigneeOptions
                    approveInsteadOfSign={extraDocument.approveInsteadOfSign}
                    signees={extraDocument.signees}
                    activeSigneeCategories={extraDocument.categories}
                    selectSignees={true}
                    onChange={onChange}
                    categoriesToShow={categoriesToShow}
                />
            </div>
            <div>
                <Icon
                    name='trash'
                    link
                    onClick={onDelete}
                />
            </div>
        </div>
    );

    return (
        <Table.Row>
            <Table.Cell colSpan={2}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '1.25em' }}>
                    <div
                        style={{
                            flex: 1,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {extraDocument.name}
                    </div>
                    <div style={{ flex: 1 }}>
                        {theOptions}
                    </div>
                </div>
            </Table.Cell>
        </Table.Row>
    );
};

const renderSigneeCategorySelector = ({ activeSigneeCategories, categoriesToShow, onChange }) => {
    return Object.values(knownCategories).filter(category => categoriesToShow.has(category.id)).map(category => {
        return (
            <Checkbox
                checked={activeSigneeCategories?.includes(category.id)}
                key={category.id}
                label={category.name}
                onChange={(_, { checked }) => onChange(category.id, checked)}
                style={{ marginRight: '1em' }}
            />
        );
    });
};

const SigneeOptions = ({ approveInsteadOfSign, categoriesToShow, activeSigneeCategories, disableModeSelection, selectSignees, onChange }) => {
    const [popupOpen, setPopupOpen] = useState(false);

    const signOrApproveDropdown = (
        <Popup
            open={popupOpen}
            onOpen={() => setPopupOpen(true)}
            onClose={() => setPopupOpen(false)}
            disabled={disableModeSelection}
            on='click'
            position='bottom left'
            basic
            offset={[0, -10]}
            style={{ padding: 0 }}
            trigger={(
                <strong style={{ cursor: disableModeSelection ? undefined : 'pointer' }}>
                    {!disableModeSelection && <Icon name='caret down' style={{ marginRight: '0.25em' }} />}
                    {approveInsteadOfSign ? 'Godkendes' : 'Underskrives'}
                </strong>
            )}
            content={[false, true].map(boolean => {
                const active = boolean === approveInsteadOfSign;
                const style = { padding: '.75em' };
                if (active) {
                    style.fontWeight = 'bold';
                    style.background = 'rgba(0, 0, 0, 0.05)';
                } else {
                    style.cursor = 'pointer';
                }
                return (
                    <div
                        key={boolean}
                        onClick={() => {
                            onChange({ approveInsteadOfSign: boolean });
                            setPopupOpen(false);
                        }}
                        style={style}
                        children={boolean ? 'Godkendes' : 'Underskrives'}
                    />
                );
            })}
        />
    );

    return (
        <div style={{ display: 'inline-block' }}>
            <div>
                {signOrApproveDropdown} af:
            </div>
            <div>
                {!selectSignees ? activeSigneeCategories : renderSigneeCategorySelector({
                    categoriesToShow,
                    activeSigneeCategories,
                    onChange: (category, checked) => {
                        const copy = [...activeSigneeCategories];
                        if (checked) {
                            copy.push(category);
                        } else {
                            copy.splice(copy.indexOf(category), 1)
                        }
                        onChange({ categories: copy });
                    },
                })}
            </div>
        </div>
    );
};

const StatusIcon = ({ status }) => {
    const { icon, color, description } = {
        Inactive: {
            icon: 'clock',
            color: 'grey',
            description: 'Afventer forsendelse',
        },

        Pending: {
            icon: 'paper plane',
            color: 'blue',
            description: 'Afventer underskrift',
        },

        Completed: {
            icon: 'check circle',
            color: 'green',
            description: 'Underskrevet',
        },
    }[status];

    return (
        <Popup
            basic
            position='top center'
            content={description}
            trigger={(
                <Icon name={icon} color={color} />
            )}
        />
    );
};

const getStep = (isCompleted, isSelected, title, icon) => {
    const titleWrapper = (
        <span style={{ fontWeight: 'bold', color: isCompleted ? '#46C476' : '#323B49' }}>{title}</span>
    );

    const future = !(isCompleted || isSelected);

    return {
        bgColorCompleted: future ? 'white' : '#EDF9F1',
        bgColorSelected: future ? 'white' : '#D3F1DE',
        borderColor: future ? 'white' : '#46C476',
        isCompleted,
        isSelected,
        title: titleWrapper,
        icon: (
            <Icon
                name={isCompleted ? 'check circle' : icon}
                color={(isCompleted || isSelected) ? 'green' : 'grey'}
            />
        ),
    };
};

const ChangeEmailIcon = ({ currentEmail, onEmailChanged }) => {
    const [open, setOpen] = useState(false);
    const [newEmail, setNewEmail] = useState();

    const handleSaveEmail = () => {
        if (currentEmail === newEmail) return;

        const emailRegexp = getEmailRegexp();
        if (!emailRegexp.test(newEmail.trim())) {
            toast.error('Den indtastede email er ikke valid!');
            return;
        }

        onEmailChanged(newEmail.trim());
        setOpen(false);
    };

    const icon = (
        <Popup
            basic
            inverted
            position='top center'
            content='Skift email'
            trigger={(
                <Icon
                    style={{ marginLeft: '0.25em' }}
                    onClick={() => {
                        setNewEmail(currentEmail);
                        setOpen(true);
                    }}
                    name='pencil'
                    link
                />
            )}
        />
    );

    return (
        <>
            {icon}
            {open && (
                <Modal open onClose={() => setOpen(false)}>
                    <Modal.Header>Skift email</Modal.Header>
                    <Modal.Content>
                        <strong>Indtast ny email</strong> <br />
                        <Input
                            fluid
                            defaultValue={newEmail}
                            onChange={(_, { value }) => setNewEmail(value)}
                            onKeyDown={e => e.key === 'Enter' && handleSaveEmail()}
                            autoFocus
                            onFocus={e => e.target.select()}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            color='black'
                            content='Annuller'
                            onClick={() => setOpen(false)}
                        />
                        <Button
                            primary
                            content='Gem'
                            disabled={currentEmail === newEmail}
                            onClick={() => {
                                const emailRegexp = getEmailRegexp();
                                if (!emailRegexp.test(newEmail.trim())) {
                                    toast.error('Den indtastede email er ikke valid!');
                                    return;
                                }

                                onEmailChanged(newEmail.trim());
                                setOpen(false);
                            }}
                        />
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};

const SendReminderButton = ({ disabled, flowOrderId, signee }) => {
    const [hasSent, setHasSent] = useState(false);
    const [working, setWorking] = useState(false);

    const onSend = async () => {
        if (working) return;
        setWorking(true);
        
        try {
            await sendReminderBySigneeReference(flowOrderId, signee.reference);
            toast.success('Påmindelse sendt!');
            setHasSent(true);
        } catch {
            toast.error('Påmindelse kunne ikke sendes...');
        }

        setWorking(false);
    };

    return (
        <ColoredText
            key={hasSent}
            onClick={onSend}
            loading={working}
            disabled={disabled || hasSent || working}
            content={hasSent ? 'Påmindelse sendt' : 'Send påmindelse'}
            underlined={false}
            link
            icon={hasSent ? 'check' : 'bell'}
        />  
    );
};

const FLOW_STATUSES = {
    NOT_STARTED: '',
    CANCELLED:   'Cancelled',
    ACTIVE:      'Active',
    COMPLETED:   'Completed',
};

const SIGNEE_STATUSES = {
    PENDING:   'Pending',
    ACTIVE:    'Active',
    COMPLETED: 'Completed',
};

const SIGNING_METHODS = {
    MIT_ID: {
        id: 'MIT_ID',
        name: 'MitID'
    },
    TOUCH: {
        id: 'TOUCH',
        name: 'Touch'
    },
};

const knownCategories = {
    boardMember: {
        id: 'boardMember',
        name: 'Samlet direktion',
    },
    accountant: {
        id: 'accountant',
        name: 'Rådgiver',
    },
    chairmanOfGeneralMeeting: {
        id: 'chairmanOfGeneralMeeting',
        name: 'Dirigent',
    },
};

const flattenSignees = (signeesByCategory, flow) => {
    const signeesFromPreviouslyCreatedFlow = {};
    for (const { signees } of flow?.steps || []) {
        for (const signee of signees) {
            signeesFromPreviouslyCreatedFlow[signee.internalID] = signee;
        }
    }

    const signees = [];

    for (const category of Object.keys(knownCategories)) {
        const signeesByNameAndRole = signeesByCategory[category] || {};
        for (const [nameAndRoleKey, { count, ...signee }] of Object.entries(signeesByNameAndRole)) {
            for (let i = 0; i < count; i++) {
                const signeeID = `${nameAndRoleKey}#${i}`;
                const signeeFromPreviousFlow = signeesFromPreviouslyCreatedFlow[signeeID];

                signees.push({
                    email: signeeFromPreviousFlow?.email || '',
                    signingMethod: signeeFromPreviousFlow?.signingMethod || '',
                    signeeID,
                    category,
                    ...signee,
                });
            }
        }
    }

    return signees;
};

const extractSigneesFromDocuments = (documents, flow) => {
    const signeesForEachDocument = [];

    for (const document of documents) {
        const signeesByCategoryForCurrentDocument = {};

        for (const { category, name, role } of document.signees) {
            const nameAndRoleKey = `${name}:${role}`;

            signeesByCategoryForCurrentDocument[category] ||= {};
            signeesByCategoryForCurrentDocument[category][nameAndRoleKey] ||= {
                count: 0,
                name,
                role,
            };
            signeesByCategoryForCurrentDocument[category][nameAndRoleKey].count++;
        }

        signeesForEachDocument.push(signeesByCategoryForCurrentDocument);
    }

    const signeesByCategoryAcrossAllDocuments = {
        [knownCategories.boardMember.id]: {},
        [knownCategories.accountant.id]: {},
        [knownCategories.chairmanOfGeneralMeeting.id]: {},
    };

    for (const signeesByCategory of signeesForEachDocument) {
        for (const category of Object.keys(knownCategories)) {
            const signeesByNameAndRole = signeesByCategory[category] || {};
            for (const [nameAndRoleKey, signee] of Object.entries(signeesByNameAndRole)) {
                if (nameAndRoleKey in signeesByCategoryAcrossAllDocuments[category]) {
                    if (signeesByCategoryAcrossAllDocuments[category][nameAndRoleKey].count < signee.count) {
                        signeesByCategoryAcrossAllDocuments[category][nameAndRoleKey] = signee;
                    }
                } else {
                    signeesByCategoryAcrossAllDocuments[category][nameAndRoleKey] = signee;
                }
            }
        }
    }

    return flattenSignees(signeesByCategoryAcrossAllDocuments, flow);
};

const findSigneesWithDuplicateNameAndEmail = categorizedSignees => {
    const signeesByNameAndEmail = {};
    for (const signee of categorizedSignees) {
        if (!signee.email) continue;
        const key = `${signee.name}:${signee.email}`;
        signeesByNameAndEmail[key] ||= [];
        signeesByNameAndEmail[key].push(signee);
    }

    const duplicates = Object.values(signeesByNameAndEmail).filter(seenSignees => {
        return seenSignees.length > 1;
    });

    return duplicates.map(signees => {
        // from first to latest in terms of signing order
        signees.sort((a, b) => {
            const idxA = Object.keys(knownCategories).indexOf(a.category);
            const idxB = Object.keys(knownCategories).indexOf(b.category);
            return idxA - idxB;
        });

        const lastSigningSignee = [...signees].pop();
        const roles = signees.map(signee => {
            return {
                title: signee.role,
                category: signee.category,
            };
        });

        return {
            name: lastSigningSignee.name,
            email: lastSigningSignee.email,
            lastSigneeCategory: lastSigningSignee.category,
            lastSigneeID: lastSigningSignee.signeeID,
            roles,
        };
    });
};

const prefillExtraDocumentsFromFlow = flow => {
    if (!flow) {
        return [];
    }

    const attachments = flow.documents.filter(document => {
        if (!document.fileID) return false;
        return document.kind === 'ATTACHMENT';
    });

    return attachments;
};

const getTaxReturnCategoriesFromFlow = flow => {
    const taxReturnDocument = (flow?.documents || []).find(document => {
        return document.kind === 'SECTION_PRINT';
    });

    return taxReturnDocument?.categories;
};

const prepareSystemDocuments = (documents = []) => {
    return documents.map(document => {
        const activeSigneeCategories = Object.values(knownCategories).filter(category => {
            return document.signees.some(signee => signee.category === category.id);
        });

        const listFormatter = new Intl.ListFormat('da-DK');
        const formattedCategories = listFormatter.format(activeSigneeCategories.map(category => {
            return category.name.toLowerCase();
        }));
        
        return {
            id: document.id,
            kind: document.kind,
            name: document.title,
            mustAlwaysSign: document.mustAlwaysSign,
            activeSigneeCategories: capitalize(formattedCategories),
            categories: activeSigneeCategories.map(category => category.id),
            secondaryDocumentIndex: document.secondaryDocumentIndex,
        };
    }).filter(document => document.activeSigneeCategories.length > 0);
};

const getDefaultSystemDocumentsToSign = (documents, flow) => {
    const systemDocuments = prepareSystemDocuments(documents);

    const out = new Set();

    const shouldSignDocument = document => {
        if (document.mustAlwaysSign) {
            return true;
        }

        if (flow?.documents) {
            const wasIncludedInPreviousFlow = (flow?.documents || []).some(docFromFlow => {
                return docFromFlow.id === document.id;
            });
    
            return wasIncludedInPreviousFlow;
        }

        if (document.categories.includes(knownCategories.accountant.id)) {
            return true;
        }

        return false;
    };

    for (const document of systemDocuments) {
        if (shouldSignDocument(document)) {
            out.add(document.id);
        }
    }

    return out;
};

const Signflow = ({ runAction, documents, flow, refetchFlow, productStatus, docgenID, errorMessage, resetStateToBeforeSignatureFlow }) => {
    const statusIsSignflowOrAbove = productStatus.step >= statusesMap.esignatur_flow.step;
    const taxReturnCategories = getTaxReturnCategoriesFromFlow(flow);
    const user = useUser();
    const documentsStepIsActive = user.isAccountant() || user.isAdmin();
    const [activeStepID, setActiveStepID] = useState(() => {
        if (statusIsSignflowOrAbove) {
            return 'signatureFlow';
        }

        if (documentsStepIsActive) {
            return 'documents';
        }

        return 'contactInformation';
    });
    const [systemDocumentsToSign, setSystemDocumentsToSign] = useState(getDefaultSystemDocumentsToSign(documents, flow));
    const [extraDocuments, setExtraDocuments] = useState(prefillExtraDocumentsFromFlow(flow));
    const [categorizedSignees, setCategorizedSignees] = useState(extractSigneesFromDocuments(documents, flow));
    const [includeTaxReturn, setIncludeTaxReturn] = useState(taxReturnCategories ? true : false);
    const [taxReturnDocument, setTaxReturnDocument] = useState({
        kind: 'SECTION_PRINT',
        name: 'Selvangivelse',
        sectionSlug: 'taxq',
        approveInsteadOfSign: true,
        categories: taxReturnCategories || [],
    });

    useEffect(() => {
        if (activeStepID === 'signatureFlow' && !statusIsSignflowOrAbove) {
            setActiveStepID('documents');
        }
    }, [activeStepID, statusIsSignflowOrAbove]);

    useEffect(() => {
        if (!flow) return;

        const onVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                refetchFlow();
            }
        };

        window.document.addEventListener('visibilitychange', onVisibilityChange);
        return () => window.document.removeEventListener('visibilitychange', onVisibilityChange);
    }, [refetchFlow, flow]);

    const systemDocuments = prepareSystemDocuments(documents);

    const categoriesToShow = new Set();
    systemDocuments.forEach(document => {
        document.categories.forEach(category => categoriesToShow.add(category));
    });

    const prepareSignees = () => {
        const signeeDuplicates = findSigneesWithDuplicateNameAndEmail(categorizedSignees);

        const preparedSignees = [];

        for (const signee of categorizedSignees) {
            const duplicate = signeeDuplicates.find(s => {
                return s.email === signee.email && s.name === signee.name;
            });

            if (!duplicate) {
                preparedSignees.push({
                    signeeID: signee.signeeID,
                    name: signee.name,
                    email: signee.email,
                    signingMethod: signee.signingMethod,
                    roles: [
                        {
                            title: signee.role,
                            category: signee.category,
                        },
                    ],
                });
            } else if (duplicate.lastSigneeID === signee.signeeID) {
                preparedSignees.push({
                    signeeID: duplicate.lastSigneeID,
                    name: signee.name,
                    email: signee.email,
                    signingMethod: signee.signingMethod,
                    roles: duplicate.roles,
                });
            }
        }

        return preparedSignees;
    };

    const updateSigneeByID = (signeeID, toUpdate = {}) => {
        setCategorizedSignees(categorizedSignees.map(signee => {
            if (signee.signeeID !== signeeID) return signee;

            return {
                ...signee,
                ...toUpdate,
            };
        }));
    };

    const renderDocumentsStep = () => {
        return (
            <Table compact style={{ margin: 0, borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>
                <Table.Body>
                    {systemDocuments.map(systemDocument => {
                        const shouldSignDocument = systemDocumentsToSign.has(systemDocument.id);
                        return (
                            <Table.Row>
                                <Table.Cell width={8} style={{ fontWeight: 'bold' }}>
                                    {systemDocument.mustAlwaysSign ? systemDocument.name : (
                                        <Checkbox
                                            label={systemDocument.name}
                                            defaultChecked={systemDocumentsToSign.has(systemDocument.id)}
                                            onChange={(_, { checked }) => {
                                                const copy = new Set(systemDocumentsToSign);
                                                if (checked) {
                                                    copy.add(systemDocument.id);
                                                } else {
                                                    copy.delete(systemDocument.id);
                                                }
                                                setSystemDocumentsToSign(copy);
                                            }}
                                        />
                                    )}
                                </Table.Cell>
                                <Table.Cell style={{ opacity: shouldSignDocument ? 1 : 0.5 }}>
                                    {renderSigneeOptions({ ...systemDocument, disableModeSelection: true })}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}

                    <Table.Row>
                        <Table.Cell width={8} style={{ fontWeight: 'bold' }}>
                            <Checkbox
                                label={taxReturnDocument.name}
                                checked={includeTaxReturn}
                                onChange={(_, { checked }) => setIncludeTaxReturn(checked)} 
                            />
                        </Table.Cell>
                        <Table.Cell
                            style={{
                                opacity: includeTaxReturn ? 1 : 0.5,
                                pointerEvents: includeTaxReturn ? undefined : 'none',
                            }}
                        >
                            {renderSigneeOptions({
                                approveInsteadOfSign: taxReturnDocument.approveInsteadOfSign,
                                activeSigneeCategories: taxReturnDocument.categories,
                                selectSignees: true,
                                disableModeSelection: true,
                                onChange: toUpdate => setTaxReturnDocument({ ...taxReturnDocument, ...toUpdate }),
                            })}
                        </Table.Cell>
                    </Table.Row>

                    {extraDocuments.length > 0 && (
                        <Table.Row>
                            <Table.Cell colSpan={3} style={{ backgroundColor: 'rgba(1, 1, 1, 0.025)' }}>
                                <strong><Icon name='attach' /> Bilag</strong> <span>({extraDocuments.length} ud af 10 tilføjet)</span>
                            </Table.Cell>
                        </Table.Row>
                    )}

                    {extraDocuments.map(extraDocument => {
                        return (
                            <ExtraDocument
                                categoriesToShow={categoriesToShow}
                                extraDocument={extraDocument}
                                onChange={toUpdate => {
                                    setExtraDocuments(extraDocuments.map(doc => {
                                        if (doc === extraDocument) {
                                            return { ...doc, ...toUpdate };
                                        }
                                        return doc;
                                    }));
                                }}
                                onDelete={() => setExtraDocuments(extraDocuments.filter(doc => {
                                    return doc !== extraDocument;
                                }))}
                            />
                        );
                    })}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={3}>
                            <FileuploadButton
                                accept={['pdf']}
                                onChange={file => setExtraDocuments([...extraDocuments, {
                                    name: file.name,
                                    kind: 'ATTACHMENT',
                                    approveInsteadOfSign: false,
                                    categories: [],
                                    rawFile: file,
                                }])}
                                trigger={(
                                    <Button
                                        fluid
                                        icon='plus'
                                        content='Tilføj bilag'
                                    />
                                )}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    };

    const renderContactInformationStep = () => {
        const activeSigneeCategories = Object.values(knownCategories).filter(category => {
            return categorizedSignees.some(signee => signee.category === category.id);
        });

        return (
            <Table compact style={{ borderRadius: 0, margin: 0 }}>
                {activeSigneeCategories.map((category) => {
                    const signees = categorizedSignees.filter(signee => {
                        return signee.category === category.id;
                    });

                    return <>
                        <Table.Row>
                            <Table.Cell style={{ backgroundColor: 'rgba(1, 1, 1, 0.025)' }}>
                                <strong>{category.name}</strong>
                            </Table.Cell>
                        </Table.Row>

                        {signees.map((signee, idx) => {
                            const signingMethodAsBoolean = {
                                [SIGNING_METHODS.MIT_ID.id]: true,
                                [SIGNING_METHODS.TOUCH.id]: false,
                            }[signee.signingMethod];

                            const duplicate = signeeDuplicates.find(s => {
                                return signee.email === s.email && signee.name === s.name;
                            });

                            let role = signee.role;
                            let roleStyle = {};
                            if (duplicate) {
                                if (duplicate.lastSigneeCategory !== signee.category) {
                                    roleStyle = { textDecoration: 'line-through', opacity: 0.75 };
                                } else {
                                    role = new Intl.ListFormat('da-DK').format(duplicate.roles.map(role => role.title));
                                }
                            }

                            const row = (
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ marginBottom: '0.25em', ...roleStyle }}>
                                            <strong style={{ opacity: 0.9 }}>{signee.name}</strong>{' ∙ '}
                                            <span style={roleStyle}>{role}</span>
                                        </div>
                                        <Input
                                            fluid
                                            size='small'
                                            placeholder='eks: mail@test.com'
                                            defaultValue={signee.email}
                                            label='Email'
                                            onChange={(_, { value }) => updateSigneeByID(signee.signeeID, {
                                                email: value.trim(),
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <div style={{ marginBottom: '0.25em', opacity: 1 }}><strong style={{ opacity: 0.9 }}>Underskrivningsmetode</strong></div>
                                        <YesNo
                                            yesLabel='MitID'
                                            noLabel='Touch'
                                            value={signingMethodAsBoolean}
                                            onChange={isUsingMitID => updateSigneeByID(signee.signeeID, {
                                                signingMethod: isUsingMitID ? SIGNING_METHODS.MIT_ID.id : SIGNING_METHODS.TOUCH.id,
                                            })}
                                        />
                                    </div>
                                </div>
                            );

                            return (
                                <Table.Row key={signee.signeeID} style={{ ...roleStyle }}>
                                    <Table.Cell style={{ borderTop: idx === 0 ? undefined  : 'none' }}>
                                        {row}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </>
                })}
            </Table>
        );
    };

    const signeeDuplicates = findSigneesWithDuplicateNameAndEmail(categorizedSignees);

    const stepsMetadata = [
        {
            id: 'documents',
            title: 'Dokumenter',
            icon: 'copy',
            isLocked: false,
            isDisabled: statusIsSignflowOrAbove,
            isHidden: !documentsStepIsActive,
            render: renderDocumentsStep,
            renderActions() {
                return (
                    <Button
                        onClick={() => setActiveStepID('contactInformation')}
                        content='Kontaktoplysninger'
                        icon='arrow right'
                        labelPosition='right'
                        primary
                    />
                );
            },
        },
        {
            id: 'contactInformation',
            title: 'Kontaktoplysninger',
            icon: 'mail',
            isLocked: false,
            isDisabled: statusIsSignflowOrAbove,
            render: renderContactInformationStep,
            renderActions() {
                const startFlow = async () => {
                    if (statusIsSignflowOrAbove) {
                        setActiveStepID('signatureFlow');
                        return;
                    }
                    
                    const signees = prepareSignees();
    
                    const validationError = validateSignflow({
                        signees,
                        documentsToValidate: [
                            ...(includeTaxReturn ? [taxReturnDocument] : []),
                            ...extraDocuments,
                        ],
                    })
    
                    if (validationError) {
                        toast.error(validationError);
                        return;
                    }
    
                    const allSignflowDocuments = [];

                    for (const document of systemDocuments) {
                        if (systemDocumentsToSign.has(document.id)) {
                            allSignflowDocuments.push(document);
                        }
                    }
    
                    if (includeTaxReturn) {
                        allSignflowDocuments.push(taxReturnDocument);
                    }
    
                    const uploadedExtraDocuments = await Promise.all(extraDocuments.map(async ({ rawFile, ...document }) => {
                        if (document.fileID) {
                            // means the document has been uploaded in a previous flow
                            return document;
                        }
    
                        const fileID = await uploadFile(rawFile);
    
                        return {
                            ...document,
                            fileID,
                        };
                    }));
    
                    allSignflowDocuments.push(...uploadedExtraDocuments);
    
                    const { executionErrors } = await runAction({
                        startSignatureFlow: true,
                        signflowOptions: {
                            signees: prepareSignees(),
                            documents: allSignflowDocuments,
                        },
                    });
    
                    const docgenError = (executionErrors || {})[docgenID];
                    if (docgenError && docgenError.internalError) {
                        toast.error('Kunne ikke starte underskriftsflowet...');
                        return;
                    }
    
                    setActiveStepID('signatureFlow');
                };

                const signeeDuplicatesMessage = signeeDuplicates.length > 0 && (
                    <Popup
                        basic
                        position='top center'
                        content={signeeDuplicates.map(signee => {
                            return (
                                <div>
                                    <b>{signee.name}</b>{' '}
                                    skal kun underskrive én gang i kategorien{' '}
                                    <b>{knownCategories[signee.lastSigneeCategory].name}</b>{' '}
                                    med rollerne:
                                    <List bulleted style={{ marginTop: '0.5em' }}>
                                        {signee.roles.map(role => <List.Item key={role.title}>{role.title}</List.Item>)}
                                    </List>
                                </div>
                            );
                        })}
                        trigger={(
                            <ColoredText
                                icon={<Icon name='question circle' color='blue' />}
                                iconPosition='left'
                                content='Underskriver sammenslåes'
                                basic
                            />
                        )}
                    />
                );

                return (
                    <>
                        {signeeDuplicatesMessage}
                        <Button
                            onClick={startFlow}
                            content={statusIsSignflowOrAbove ? 'Se underskriftsflow' : 'Send til underskrift'}
                            icon={statusIsSignflowOrAbove ? 'arrow right' : 'paper plane'}
                            labelPosition='right'
                            primary
                        />
                    </>
                );
            },
        },
        {
            id: 'signatureFlow',
            title: 'Underskriftsflow',
            icon: 'signup',
            isLocked: !statusIsSignflowOrAbove,
            isDisabled: !statusIsSignflowOrAbove,
            render() {
                if (!flow) {
                    if (productStatus.step >= statusesMap.done.step) {
                        return null;
                    }

                    return <Loader inline='centered' active />
                }

                let previousStepComplete = true;

                const renderedSteps = flow.steps.map((step, stepIdx) => {
                    const completedSignees = step.signees.filter(signee => {
                        return signee.status === 'Completed';
                    }).length;

                    const percentageDone = completedSignees / step.signees.length * 100;

                    let stepStatus;
                    if (percentageDone === 100) {
                        stepStatus = 'Completed'
                    } else if (previousStepComplete) {
                        stepStatus = 'Pending';
                    } else {
                        stepStatus = 'Inactive';
                    }

                    const signees = step.signees.map(signee => {
                        const signeeStatus = previousStepComplete ? signee.status : 'Inactive';
                        const stepIsPending = stepStatus === 'Pending';
                        const signeeIsPending = signeeStatus === 'Pending';

                        return (
                            <Table.Row style={{ opacity: stepStatus === 'Inactive' ? 0.5 : 1, pointerEvents: stepStatus === 'Inactive' ? 'none' : undefined }}>
                                <Table.Cell>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.5em' }}>
                                        <div>
                                            <StatusIcon status={signeeStatus} />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <b>{signee.name}</b> ∙ {signee.roles.join(', ')}
                                            <br />
                                            <b>Email:</b> {signee.email}
                                            {stepIsPending && signeeIsPending && (
                                                <ChangeEmailIcon
                                                    currentEmail={signee.email}
                                                    onEmailChanged={async newEmail => {
                                                        try {
                                                            await updateEmail(flow.orderId, signee.reference, signee.email, newEmail);
                                                            await refetchFlow();
                                                            toast.success('Email opdateret og notifikation sendt');
                                                        } catch {
                                                            toast.error('Email kunne ikke opdateres...');
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {stepIsPending && (
                                            <SendReminderButton
                                                disabled={!signeeIsPending}
                                                flowOrderId={flow.orderId}
                                                signee={signee}
                                            />
                                        )}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        );
                    });


                    const progressBarRow = stepStatus !== 'Inactive' && (
                        <Table.Row>
                            <Table.Cell colSpan={3} style={{ padding: 0, borderRadius: 0 }}>
                                <ProgressBar
                                    percentage={percentageDone}
                                    isFirst={false}
                                    isLast={true}
                                />
                            </Table.Cell>
                        </Table.Row>
                    );

                    const stepHeader = (
                        <Table.Row>
                            <Table.Cell colSpan={3} style={{ background: 'rgba(1, 1, 1, 0.025)' }}>
                                <span style={{ fontWeight: stepStatus !== 'Inactive' ? 'bold' : undefined }}>Underskriftsgruppe nr. {stepIdx + 1}</span>
                                
                            </Table.Cell>
                        </Table.Row>
                    );

                    previousStepComplete = percentageDone === 100;

                    return (
                        <>
                            {stepHeader}
                            {progressBarRow}
                            {signees}
                        </>
                    );
                });

                return (
                    <Table compact='very' style={{ margin: 0, borderRadius: 0 }}>
                        <Table.Body>
                            {renderedSteps}
                        </Table.Body>
                    </Table>
                );
            },
            renderActions() {
                if (productStatus === statusesMap.esignatur_flow) {
                    return (
                        <UnlockModal
                            onUnlock={() => {
                                resetStateToBeforeSignatureFlow()
                                    .then(() => toast.success('Underskriftsflow stoppet'))
                                    .catch(() => toast.error('Kunne ikke stoppe underskriftsflow...'));
                            }}
                            trigger={(
                                <Button
                                    content='Stop underskriftsflow'
                                    icon='ban'
                                    color='black'
                                    basic
                                />
                            )}
                        />
                    );
                }

                if (productStatus.step > statusesMap.esignatur_flow.step) {
                    const downloadButtonIsDisabled = (flow?.documents || []).length === 0;
                    return (
                        <SignatureFlowFilesModal
                            flow={flow}
                            disabled={downloadButtonIsDisabled}
                            trigger={(
                                <Button
                                    disabled={downloadButtonIsDisabled}
                                    content='Se underskrevne dokumenter'
                                    primary
                                    basic
                                />
                            )}
                        />
                    );
                }

                return null
            },
        },
    ].filter(step => !step.isHidden);

    const selectedStep = stepsMetadata.find(step => step.id === activeStepID);

    const steps = stepsMetadata.map(({ id, title, icon, isLocked }, idx) => {
        const activeStepIndex = stepsMetadata.findIndex(step => step.id === activeStepID);

        const isCompleted = activeStepIndex > idx;
        const isSelected = activeStepIndex === idx;

        return {
            ...getStep(isCompleted, isSelected, title, icon),
            locked: isLocked,
            onSelected: () => !isLocked && setActiveStepID(id),
        };
    });

    const renderSigneeOptions = ({ approveInsteadOfSign, activeSigneeCategories, disableModeSelection, selectSignees, onChange }) => {
        const signOrApproveDropdown = (
            <Popup
                disabled={disableModeSelection}
                on='click'
                position='bottom left'
                basic
                offset={[0, -10]}
                style={{ padding: 0 }}
                trigger={(
                    <strong style={{ cursor: disableModeSelection ? undefined : 'pointer' }}>
                        {!disableModeSelection && <Icon name='caret down' style={{ marginRight: '0.25em' }} />}
                        {approveInsteadOfSign ? 'Godkendes' : 'Underskrives'}
                    </strong>
                )}
                content={[false, true].map(boolean => {
                    const active = boolean === approveInsteadOfSign;
                    const style = { padding: '.75em' };
                    if (active) {
                        style.fontWeight = 'bold';
                        style.background = 'rgba(0, 0, 0, 0.05)';
                    } else {
                        style.cursor = 'pointer';
                    }
                    return (
                        <div
                            key={boolean}
                            onClick={() => onChange({ approveInsteadOfSign: boolean })}
                            style={style}
                            children={boolean ? 'Godkendes' : 'Underskrives'}
                        />
                    );
                })}
            />
        );

        return (
            <>
                <div>
                    {signOrApproveDropdown} af:
                </div>
                {!selectSignees ? activeSigneeCategories : renderSigneeCategorySelector({
                    categoriesToShow,
                    activeSigneeCategories,
                    onChange: (category, checked) => {
                        const copy = [...activeSigneeCategories];
                        if (checked) {
                            copy.push(category);
                        } else {
                            copy.splice(copy.indexOf(category), 1)
                        }
                        onChange({ categories: copy });
                    },
                })}
            </>
        );
    };

    const mainContent = (
        <div style={{ opacity: errorMessage ? 0.75 : 1, pointerEvents: errorMessage ? 'none' : undefined }}>
            <Stepper
                offset={5}
                height={50}
                selectedStepIndex={stepsMetadata.findIndex(step => step.id === activeStepID)}
                steps={steps}
            />
            <div style={{ opacity: selectedStep.isDisabled ? 0.75 : 1, pointerEvents: selectedStep.isDisabled ? 'none' : undefined }}>
                {selectedStep.render()}
            </div>
            <div style={{ padding: '1em', gap: '0.5em', display: 'flex', alignItems: 'center', justifyContent: 'right', flexDirection: 'row', background: 'rgba(0, 0, 0, 0.05)' }}>
                {selectedStep.renderActions()}
            </div>
        </div>
    );

    return (
        <div>
            {errorMessage && (
                <div style={{ padding: '0.75em', borderBottom: '1px solid lightgray' }}>
                    <strong>
                        <Icon name='warning sign' /> Rapporten kan ikke underskrives:
                    </strong>
                    <br />
                    {errorMessage}
                </div>
            )}
            {mainContent}
        </div>
    );
};

export default Signflow;